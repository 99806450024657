<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "编辑" : "添加" }}封闭停车场
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="140px"
          :model="formInline"
          style="width: 350px; float: left"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item label="停车场父子类型" prop="slaveRelation">
            <el-select
              @change="formInline.amount = ''"
              v-model.trim="formInline.slaveRelation"
              :disabled="this.isEdit"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in slaveRelation"
                :key="item.code"
                :label="item.desc"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="关联父车场"
            :prop="formInline.slaveRelation == 2 ? 'parentId' : ''"
            v-show="formInline.slaveRelation == 2"
          >
            <el-select
              :disabled="this.isEdit"
              v-model.trim="formInline.parentId"
              filterable
              placeholder="请选择"
              @change="slaveRelationChange"
            >
              <el-option
                :label="value.parkName"
                :value="value.parkId"
                :key="value.parkId"
                v-for="value in parkParent"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.park_name')" prop="parkName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.parkName"
              placeholder="请输入停车场名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车场编码" prop="parkAlias">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.parkAlias"
              placeholder="请输入停车场编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="停车场物理类型" prop="closedParkType" style="display: inline-block">
            <el-select
              v-model.trim="formInline.closedParkType"
              filterable
              placeholder="请选择停车场物理类型"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in parkType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="泊位数"
            :prop="formInline.slaveRelation != 1 ? 'amount' : ''"
            v-show="formInline.slaveRelation != 1"
          >
            <el-input
              :maxlength="10"
              v-model="formInline.amount"
              placeholder="请输入泊位数"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <el-button
              :title="areaName ? areaName : '请选择'"
              @click="areaIdDialog"
              :disabled="formInline.slaveRelation == 2"
              class="dialog_button"
              >{{ areaName ? areaName : "请选择" }}
            </el-button>
          </el-form-item>
          <el-form-item class="hotArea" label="所属热门商圈">
            <el-transfer
              target-order="push"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="请输入商圈名称"
              v-model="formInline.hotValue"
              :props="{
                key: 'businessCenterId',
                label: 'businessCenterName',
              }"
              :titles="['未选择', '已选择']"
              :data="hotData"
            >
            </el-transfer>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              :disabled="formInline.slaveRelation == 2 || isEdit"
              v-model.trim="formInline.operationId"
              filterable
              size="15"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经纬度" prop="longitude" class="longitude">
            <el-input
              :maxlength="20"
              :disabled="formInline.slaveRelation == 2"
              v-model.trim="lnglat"
              placeholder="在地图上选择坐标点"
            ></el-input>
            <el-button
              type="primary"
              class="map1"
              style="margin-top: 0"
              @click="openMap"
              :disabled="formInline.slaveRelation == 2"
              >地图选点
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('searchModule.address')" prop="parkAddress" style="width: 400px">
            <el-input
              type="textarea"
              :disabled="formInline.slaveRelation == 2"
              :maxlength="20"
              v-model.trim="formInline.parkAddress"
              placeholder="输入车场详细地址"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.charge_type')" prop="scopeId">
            <el-select v-model="formInline.scopeId" :disabled='formInline.slaveRelation == 2'  placeholder="请选择">
                <el-option :label="v.desc" :value="v.code + ''" :key='v.code' v-for='v in parkScope'></el-option>
              </el-select>
          </el-form-item> -->
          <el-form-item label="是否商业车场" prop="businessType">
            <el-select
              v-model="formInline.businessType"
              :disabled="formInline.slaveRelation == 2"
              placeholder="请选择是否商业车场"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in businessType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否承包" prop="contract">
            <el-radio
              :disabled="formInline.slaveRelation == 2"
              v-model="formInline.contract"
              :label="0"
              >是</el-radio
            >
            <el-radio
              :disabled="formInline.slaveRelation == 2"
              v-model="formInline.contract"
              :label="1"
              >否</el-radio
            >
          </el-form-item>
          <el-form-item label="合作模式" prop="cooperationModel">
            <el-select
              v-model="formInline.cooperationModel"
              :disabled="formInline.slaveRelation == 2"
              placeholder="请选择合作模式"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in cooperationModel"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场内支付" prop="innerPayable">
            <el-select
              v-model="formInline.innerPayable"
              :disabled="formInline.slaveRelation == 2"
              placeholder="请选择是否场内支付"
            >
              <!-- <el-option :label="v.desc" :value="v.code" :key='v.code' v-for='v in parkState'></el-option> -->
              <el-option label="支持" :value="1"></el-option>
              <el-option label="不支持" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Payment_method')" prop="payMode">
            <el-select
              v-model="formInline.payMode"
              :disabled="formInline.slaveRelation == 2"
              placeholder="请选择支付方式"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in payMode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="停车场开放时间" prop="openStartTime">
            <el-time-picker
              is-range
              v-model="openTime"
              value-format="HH:mm:ss"
              range-separator="~"
              @change="openTimeChange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Commercial_status')" prop="commercialStatus">
            <el-select v-model="formInline.commercialStatus" placeholder="请选择是否商用状态">
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in commercialStatus"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="商用状态生成时间" :prop="formInline.commercialStatus != 1 ? 'bootTime' : ''" v-show='formInline.commercialStatus != 1'>
              <div>
                  <div>
                    <el-radio v-model="effective" label="1">立即生效</el-radio>
                    <el-radio v-model="effective" label="2">定时生效</el-radio>
                  </div>
                  <div v-show='effective == 2'>
                    <el-date-picker
                      v-model="formInline.bootTime"
                      type="datetime"
                      placeholder="选择日期时间"
                      format='yyyy-MM-dd HH:00:00'
                      value-format='yyyy-MM-dd HH:00:00'
                      >
                    </el-date-picker>
                  </div>
              </div>
          </el-form-item> -->
          <el-form-item label="车场状态" prop="parkState">
            <el-select v-model="formInline.parkState" placeholder="请选择车场状态">
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in parkState"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否支持充电" prop="chargeable">
            <el-radio v-model="formInline.chargeable" :label="0">否</el-radio>
            <el-radio v-model="formInline.chargeable" :label="1">是</el-radio>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Access_method')" prop="parkAccessType">
            <el-select v-model="formInline.parkAccessType" placeholder="请选择接入方式">
              <el-option
                :label="v.desc"
                :value="v.code"
                :key="v.code"
                v-for="v in accessList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收费规则描述" prop="thirdFeeDesc">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="formInline.thirdFeeDesc"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="uploadPic">
          <el-upload
            ref="upload"
            class="avatar-uploader2"
            action="/acb/2.0/park/upload"
            :headers="uploadHeader"
            :data="uploadData"
            name="image"
            :on-change="imgChange"
            :file-list="fileList"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar2" />
            <i v-else class="el-icon-plus avatar-uploader-icon2"></i>
          </el-upload>
        </div>
      </div>
      <div style="text-align: center; margin-left: 8.5%">
        <el-button type="primary" @click="submitData" style="width: 88px">提交</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
    <map-con ref="mapConpent" :city="city" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
    <tree-con
      ref="treeConpent"
      :defaultProps="defaultProps"
      :treeData="treeData"
      @treeSure="treeSure"
      @treeCandle="treeCandle"
    ></tree-con>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import mapCon from "./map";
import treeCon from "./tree";

export default {
  name: "hello",
  data() {
    let checkBerthNum = (rule, value, callback) => {
      if (value != parseInt(value)) {
        return callback(new Error("泊位数量必须为整数"));
      }
      if (value <= 0) {
        this.flag = false;
        callback(new Error("泊位数量不能小于或等于0"));
      } else {
        this.flag = true;
        callback();
      }
    };
    return {
      fileList: [],
      uploadImg: null,
      openTime: "",
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      tenantList: [],
      accessList: [
        {
          code: 1,
          desc: "平台接入",
        },
        {
          code: 2,
          desc: "三方接入",
        },
        {
          code: 4,
          desc: "同步接入",
        },
      ], // 接入方式列表
      hotData: [],
      formInline: {
        chargeable: 0,
        closedParkType: "",
        parkName: "",
        hotValue: [],
        areaId: "",
        parkAlias: "",
        slaveRelation: 0,
        innerPayable: "",
        parentId: "",
        parkAddress: "",
        imageUrl: "",
        amount: "",
        parkType: "3",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        contract: "",
        openStartTime: "",
        openStopTime: "",
        operationId: "",
        parkAccessType: "", // 接入方式
        thirdFeeDesc: "", // 计费规则描述
      },
      rules: {
        closedParkType: [
          {
            required: true,
            message: "请选择物理类型",
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        parkAlias: [
          {
            required: true,
            message: "请输入停车场编码",
            trigger: "blur",
          },
        ],
        openStartTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
        openStopTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: ["blur", "change"],
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入泊位数",
            trigger: "blur",
          },
          { validator: checkBerthNum, trigger: "blur" },
        ],
        commercialStatus: [
          {
            required: true,
            message: "请选择商用状态",
            trigger: "blur",
          },
        ],
        contract: [
          {
            required: true,
            message: "请选择是否承包",
            trigger: "blur",
          },
        ],
        businessType: [
          {
            required: true,
            message: "请选择是否商业车场",
            trigger: "blur",
          },
        ],
        innerPayable: [
          {
            required: true,
            message: "请选择场内支付",
            trigger: "blur",
          },
        ],
        payMode: [
          {
            required: true,
            message: "请选择支付方式",
            trigger: "blur",
          },
        ],
        cooperationModel: [
          {
            required: true,
            message: "请选择合作模式",
            trigger: "blur",
          },
        ],
        scopeId: [
          {
            required: true,
            message: "请选择计费类型",
            trigger: "blur",
          },
        ],
        parkName: [
          {
            required: true,
            message: "请输入停车场名称",
            trigger: "blur",
          },
          // , {validator: checkName, trigger: 'blur'}
        ],
        parkType: [
          {
            required: true,
            message: "请选择停车场类型",
            trigger: "blur",
          },
        ],
        parentId: [
          {
            required: true,
            message: "请选择停车场名称",
            trigger: "blur",
          },
        ],
        parkState: [
          {
            required: true,
            message: "请选择停车场状态",
            trigger: "blur",
          },
        ],
        slaveRelation: [
          {
            required: true,
            message: "请选择父子类型",
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            required: true,
            message: "请在地图选点",
            trigger: ["blur", "change"],
          },
        ],
        parkAddress: [
          {
            required: true,
            message: "请输入车场详细地址地址",
            trigger: ["blur", "change"],
          },
        ],
        bootTime: [
          {
            required: true,
            message: "请选择启用时间",
            trigger: "blur",
          },
        ],
        chargeable: [
          {
            required: true,
            message: "请选择是否支持充电",
            trigger: "blur",
          },
        ],
        parkAccessType: [
          {
            required: true,
            message: "请选择接入方式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    openTimeChange(value) {
      this.formInline.openStartTime = value[0];
      this.formInline.openStopTime = value[1];
    },
    slaveRelationChange(parkId) {
      this.getParentDetail(parkId);
      this.getHotAreaDetails(parkId);
      if (this.formInline.slaveRelation == 2) {
        this.hotData = this.hotData.map((res) => {
          res.disabled = true;
          return res;
        });
      }
    },
    getParentDetail(parkId) {
      if (!parkId) return;
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.areaName = data.areaName;
          this.formInline.areaId = data.areaId;
          this.formInline.operationId = data.operationId;
          this.businessName = data.operationName;
          this.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.formInline.businessType = data.businessType;
          this.formInline.longitude = data.longitude;
          this.formInline.latitude = data.latitude;
          this.formInline.parkAddress = data.parkAddress;
          this.formInline.scopeId = data.scopeId;
          this.formInline.contract = data.contract;
          this.formInline.cooperationModel = data.cooperationModel;
          this.formInline.commercialStatus = data.commercialStatus;
          this.formInline.innerPayable = data.innerPayable;
          this.formInline.payMode = data.payMode;
          this.formInline.chargeable = data.chargeable ? data.chargeable : 0;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    imgChange(file, list) {
      this.fileList = list.slice(-1);
      this.uploadImg = file;
      this.fileChang = true;
      this.imageUrl = file.url;
      if (!this.imageUrl) this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess(res) {
      if (res.state == 0) {
        this.$router.push("/parkList");
      } else {
        this.$alert(res.desc, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    areaIdDialog() {
      this.defaultProps = {
        label: "areaName",
        children: "childs",
      };
      this.key1 = "areaId";
      this.$refs.treeConpent.open();
    },
    getArea() {
      let url = "/acb/2.0/managerArea/areaTree";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.treeData = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParentPark() {
      let url = "/acb/2.0/park/getParentParks";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
            parkType: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkParent = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    sortArea(res) {
      let arr = [];
      res.forEach((value) => {
        value.childs = [];
        if (value.parentId == 0) {
          arr.push(value);
        }
      });

      function getChild(data) {
        data.forEach((value) => {
          value.childs = res.filter((child) => {
            if (child.depth == 2) {
              child.disabled = true;
            }
            return child.parentId == value.areaId;
          });
          getChild(value.childs);
        });
      }

      getChild(arr);
      return arr;
    },
    openMap() {
      //  let city = this.treeData[0].areaName;
      this.$refs.mapConpent.openMap();
      this.$refs.mapConpent.lnglat = this.lnglat;
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    treeSure(data) {
      if (data.length > 1) {
        this.$alert("只能选择一个节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      } else if (data.length == 0) {
        this.$alert("您未选择节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.key1 == "areaId") {
        this.formInline[this.key1] = data[0]["areaId"];
        this.areaName = data[0]["areaName"];
      }
      this.$refs.treeConpent.close();
    },
    treeCandle() {},
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    handleTime(data) {
      this.formInline.bootTime = dateFormat(data, "yyyy-MM-dd HH");
    },
    getBusinss() {
      let url = "/acb/2.0/manager/list";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.butreeData = [res.value];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkType = data["closedParkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkState = data["parkState"];
          this.payMode = data["payMode"];
          this.parkScope = data["parkScope"];
          this.cooperationModel = data["cooperationModel"];
          this.slaveRelation = data["slaveRelation"];
          this.businessType = data["businessType"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    createPark() {
      this.formInline.parkType = 3;
      if (this.formInline.commercialStatus == 1) {
        delete this.formInline.bootTime;
      } else if (this.effective == 1) {
        this.formInline.bootTime = dateFormat(new Date(), "yyyy-MM-dd HH") + ":00:00";
      }
      let url = this.isEdit ? "/acb/2.0/park/modify" : "/acb/2.0/park/add";
      let method = "post";
      if (this.isEdit) {
        this.formInline.parkId = this.$route.query.parkId;
      }
      this.$axios[method](url, {
        data: this.formInline,
      }).then((res) => {
        if (res.state == 0) {
          this.uploadData.parkId = this.isEdit ? this.$route.query.parkId : res.value;
          if (this.isEdit) {
            if (this.fileChang) {
              this.$refs.upload.submit();
            } else {
              this.$router.push("/parkList");
            }
          } else {
            this.$refs.upload.submit();
          }
          this.$axios
            .post("acb/2.0/businessCenter/addPark", {
              data: {
                parkId: this.uploadData.parkId,
                businessCenterIds: this.formInline.hotValue.join(),
              },
            })
            .then((res) => {
              // console.log(res)
            });
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    submitData() {
      if (!this.imageUrl) {
        this.$alert("请选择图片", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.uploadImg) {
        const isJPG = this.uploadImg.raw.type === "image/jpeg";
        const isPNG = this.uploadImg.raw.type === "image/png";
        const isLt2M = this.uploadImg.raw.size / 1024 / 1024 < 2;
        if (!isJPG && !isPNG) {
          this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
          return;
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
          return;
        }
      }
      if (this.openTime) {
        this.formInline.openStartTime = this.openTime[0];
        this.formInline.openStopTime = this.openTime[1];
      } else {
        this.formInline.openStartTime = "";
        this.formInline.openStopTime = "";
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.createPark();
        } else {
          return false;
        }
      });
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          for (let i in this.formInline) {
            this.formInline[i] = data[i];
          }
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.openTime = [this.formInline.openStartTime, this.formInline.openStopTime];
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.imageUrl = res.value;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    filterMethod(query, item) {
      return item.businessCenterName.indexOf(query) > -1;
    },
    // 热门商圈
    getHotData() {
      let url = "acb/2.0/businessCenter/getAll";
      this.$axios.get(url).then((res) => {
        this.hotData = res.value;
      });
    },
    getHotAreaDetails(id) {
      let url = "acb/2.0/businessCenter/getByParkId";
      this.$axios
        .get(url, {
          data: {
            parkId: id,
          },
        })
        .then((res) => {
          if (this.formInline.slaveRelation == 2) {
            this.hotData = this.hotData.map((res) => {
              res.disabled = true;
              return res;
            });
          }
          this.formInline.hotValue = res.value.map((res) => {
            return res.businessCenterId;
          });
        });
    },
  },
  components: {
    mapCon,
    treeCon,
  },
  created() {
    //  this.getArea();
    //  this.getBusinss();
    this.getTenantList();
    this.queryTypeList();
    this.getParentPark();
    this.getHotData();
    if (this.$route.query.parkId) {
      this.isEdit = true;
      this.getParkImg(this.$route.query.parkId);
      this.getParkDetail(this.$route.query.parkId);
      setTimeout(() => {
        this.getHotAreaDetails(this.$route.query.parkId);
      }, 150);
      this.effective = "2";
    }
  },
  mounted() {},
  computed: {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.form_lng >>> .el-form-item__content
  width 100%
  line-height:32px;
.form_lng >>> .el-input
  width 60%
.Wrapper
  overflow hidden
.breadcrumb
  height 35px
.content

  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
.map1
  display inline-block
  margin-left 5px
  position absolute
  top 0
.uploadPic
  float left
  margin-left 150px
</style>
<style>
.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader2 .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 295px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar2 {
  width: 295px;
  height: 178px;
  display: block;
}

.dialog_button {
  width: 100%;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hotArea {
  width: 800px;
}
</style>
